import { FC, useEffect, useState } from 'react'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import PaymentModalQuickChoice from 'pages/dashboard/modals/payment/components/PaymentModalQuickChoice'
import PaymentModalForm from 'pages/dashboard/modals/payment/components/PaymentModalForm'
import ModalFooter from 'layout/components/modal/shared/ModalFooter'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import PaymentMethodForm from 'pages/dashboard/modals/shared/PaymentMethodForm'
import { useForm } from 'react-hook-form'
import QRcode from 'pages/dashboard/modals/payment/components/QRcode'
import { generateQRPaymentData } from 'pages/dashboard/utils/generateQRPaymentData'
import { TCustomer } from 'api/services/customer/types'
import { TLoanResponse } from 'api/services/loan/types'
import { ButtonCommon, ButtonWithLoader } from '@vistacredit/lib-ui'
import { classNames } from 'utils'
import { Simulate } from 'react-dom/test-utils'
import load = Simulate.load

interface IPaymentModalProps {
    open: boolean
    setOpen: (value: boolean) => void
    loan?: TLoanResponse
    customer?: TCustomer
}

const data = [
    {
        id: 1,
        title: '25%',
        description: 'z částky ke splacení.',
        value: '25',
    },
    {
        id: 2,
        title: '50%',
        description: 'z částky ke splacení.',
        value: '50',
    },
    {
        id: 3,
        title: '100%',
        description: 'z částky ke splacení.',
        value: '100',
    },
]

const PaymentModal: FC<IPaymentModalProps> = ({ open, setOpen, loan, customer }) => {
    const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false)
    const [showQRCode, setShowQRCode] = useState(false)
    const [showPaymentForm, setShowPaymentForm] = useState(true)
    const [showError, setShowError] = useState(false)
    const [selectedData, setSelectedData] = useState(data[2].value)
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [selectedMethodObj, setSelectedMethodObj] = useState<{
        sid: string
        text: string
        method: () => Promise<void> | void | null
    }>({
        sid: '',
        text: '',
        method: () => null,
    })
    const form = useForm({ defaultValues: { price: loan?.sum } })

    const handleCloseModal = () => {
        setOpen(false)
        setTimeout(() => {
            setShowPaymentForm(true)
            setShowPaymentMethodForm(false)
            setShowQRCode(false)
            setSelectedMethodObj({
                sid: '',
                text: '',
                method: () => null,
            })
        }, 500)
    }

    const handleDisableButtonLogic = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (+form.getValues('price') < 500 || form.getValues('price') === '') {
            setShowError(true)
            setDisabled(true)
        } else {
            setShowError(false)
            setDisabled(false)
        }
    }

    const handleShowQRCode = () => {
        setShowQRCode(true)
        setShowPaymentMethodForm(false)
    }

    const handleMovementThroughSteps = () => {
        if (!showPaymentMethodForm && !showQRCode) {
            setShowPaymentMethodForm(true)
            setShowPaymentForm(false)
        } else {
            handleCloseModal()
        }
    }

    useEffect(() => {
        handleDisableButtonLogic()
    }, [form.getValues('price')])

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <ModalTitle title={'Platba úvěru'} />

            {showPaymentMethodForm && (
                <PaymentMethodForm
                    setShowQRCode={handleShowQRCode}
                    order_id={loan?.order_id ?? 0}
                    loan={loan}
                    type={'loan'}
                    amount={form.getValues('price') ?? 0}
                    onClose={handleCloseModal}
                    selectedMethod={selectedMethodObj}
                    setMethod={setSelectedMethodObj}
                />
            )}
            {showQRCode && (
                <QRcode
                    paymentData={generateQRPaymentData(
                        'loan',
                        form.getValues('price') ?? 0,
                        customer?.birth_number,
                        loan?.contract_number,
                    )}
                />
            )}
            {showPaymentForm && (
                <>
                    <PaymentModalForm loan={loan} form={form} setSelectedData={setSelectedData} />
                    {showError && (
                        <p className='text-sm mt-3 text-red-500'>
                            Minimální částka pro platbu je 500 Kč
                        </p>
                    )}
                    <PaymentModalQuickChoice
                        data={data}
                        loan={loan}
                        form={form}
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                    />
                </>
            )}

            {showPaymentMethodForm ? (
                <div
                    className={classNames(
                        selectedMethodObj.sid !== ''
                            ? 'grid content-center items-center gap-y-2 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'
                            : 'flex items-center w-full',
                        'mt-6 sm:mt-6 ',
                    )}>
                    <ButtonCommon
                        className='bg-red-600 hover:bg-red-700 mt-6 sm:mt-0'
                        onClick={handleCloseModal}>
                        Zrušit
                    </ButtonCommon>
                    {selectedMethodObj.text !== '' && (
                        <ButtonWithLoader
                            onClick={async () => {
                                setLoading(true)
                                await selectedMethodObj.method()
                            }}
                            disabled={loading}
                            loading={loading}>
                            {selectedMethodObj.text}
                        </ButtonWithLoader>
                    )}
                </div>
            ) : (
                <ModalFooter
                    onClose={handleCloseModal}
                    disabled={disabled}
                    onClick={handleMovementThroughSteps}
                    title={showQRCode ? 'Rozumím' : 'Zaplatit'}
                    showCancelBtn={selectedMethodObj?.sid === 'qr_payment'}
                />
            )}
        </ModalWrapper>
    )
}

export default PaymentModal
