import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { TContractsProcess, TNotification, TUserFlags } from 'store/types'

interface AppStateState {
    token: string | null
    flags: TUserFlags | null
    currentStep: number
    prePendingDeferralOrder: number | null
    contractsProcess: TContractsProcess
    flagWasSet: boolean
    notification: TNotification | null
}

const initialState = {
    token: '',
    flags: null,
    currentStep: 0,
    prePendingDeferralOrder: null,
    notification: null,
    flagWasSet: false,
    contractsProcess: { type: 'NONE', message: '' },
} as AppStateState

const appSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string | null>) => {
            state.token = action.payload
        },
        setFlags: (state, action: PayloadAction<TUserFlags>) => {
            state.flags = action.payload
        },
        setPrePendingDeferralOrder: (state, action: PayloadAction<number | null>) => {
            state.prePendingDeferralOrder = action.payload
        },
        setNotification: (state, action: PayloadAction<TNotification | null>) => {
            state.notification = action.payload
        },
        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload
        },
        setFlagWasSet: (state, action: PayloadAction<boolean>) => {
            state.flagWasSet = action.payload
        },
        setContractsProcess: (state, action: PayloadAction<TContractsProcess>) => {
            state.contractsProcess = action.payload
        },
        resetState: () => initialState,
    },
})

export const {
    setToken,
    setFlags,
    setCurrentStep,
    setPrePendingDeferralOrder,
    setNotification,
    setFlagWasSet,
    setContractsProcess,
    resetState,
} = appSlice.actions
export default appSlice
