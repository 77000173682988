import { PageWrapLayout } from 'layout/components'
import React, { FC, useEffect } from 'react'
import LoanCard from 'pages/dashboard/components/LoanCard'
import RentBanner from 'pages/dashboard/components/RentBanner'
import LoanCardMobile from 'pages/dashboard/components/LoanCardMobile'
import PaymentModal from 'pages/dashboard/modals/payment/PaymentModal'
import DeferralModal from 'pages/dashboard/modals/deferral/DeferralModal'
import LoanSkeletonLoader from 'pages/dashboard/components/LoanSkeletonLoader'
import DeferralCard from 'pages/dashboard/components/DeferralCard'
import CancelationModal from './modals/cancelationModal/CancelationModal'
import DeferralCardMobile from './components/DeferralCardMobile'
import { Contract } from 'api/contracts/types'
import AmendmentModal from 'pages/dashboard/modals/amendment/AmendmentModal'
import DetailModal from 'pages/loans/modals/DetailModal'
import ActivateRentBanner from 'pages/dashboard/components/ActivateRentBanner'
import { UserCategory } from 'utils/customerCategory'
import RentModal from 'pages/dashboard/modals/rent/RentModal'
import UploadPapersBanner from 'pages/dashboard/components/UploadPapersBanner'
import LoanRequestBanner from 'pages/dashboard/components/LoanRequestBanner'
import { useDashboardLogic } from 'pages/dashboard/hooks/useDashboardLogic'
import SignContractBannerSigni from 'pages/dashboard/components/SignContractBannerSigni'
import SigniContractRejectModal from 'pages/dashboard/modals/signContract/SignContractRejectModal'
import AmendmentRSModal from 'pages/dashboard/modals/amendment/AmendmentRSModal'

const Dashboard: FC = () => {
    const {
        activeLoanQuery,
        customerQuery,
        loanDetailQuery,
        deferralOrderQuery,
        lazyContractsToSignQuery,
        contractsToSignQuery,
        showLoanDetailModal,
        showPaymentForm,
        showPaymentMethodForm,
        showRentModal,
        showSignContractModal,
        openPaymentModal,
        openAmendmentModal,
        openCancellationModal,
        openDeferralModal,
        setShowLoanDetailModal,
        setOpenAmendmentModal,
        setClosedSignContractModal,
        setOpenPaymentModal,
        setOpenDeferralModal,
        setOpenCancellationModal,
        setShowPaymentForm,
        setShowPaymentMethodForm,
        setShowRentModal,
        setShowSignContractModal,
        dispatch,
        handleDeferral,
        handleCancellationDeferral,
        handleDisableButtonLogic,
        handleLoanDetail,
        handlePayDeferral,
        handleSignDistanceAmendment,
        openAmendmentRSModal,
        setOpenAmendmentRSModal,
    } = useDashboardLogic()

    useEffect(() => {
        (async () => {
            customerQuery.refetch()
            await lazyContractsToSignQuery()
                .unwrap()
                .then((data) => {
                    contractsToSignQuery.data = data
                })

            if (
                contractsToSignQuery?.data &&
                contractsToSignQuery?.data?.results?.filter(
                    (contract: Contract) => contract.type === 12,
                )?.length > 0
            ) {
                setOpenAmendmentRSModal(true)
            }
        })()
    }, [])

    if (!activeLoanQuery?.data && !contractsToSignQuery?.data && !customerQuery?.data) {
        return <LoanSkeletonLoader />
    }

    return (
        <PageWrapLayout>
            <section aria-labelledby='applicant-information-title'>
                <div className={'md:mt-0 grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12'}>
                    {/* First col */}
                    {!activeLoanQuery?.data && (
                        <div className='flex flex-wrap flex-col col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-6'>
                            <LoanRequestBanner
                                handleDisableButtonLogic={handleDisableButtonLogic}
                            />

                            {!customerQuery?.data?.rent_active &&
                                customerQuery?.data?.category === UserCategory.Business && (
                                    <ActivateRentBanner
                                        showRentModal={() => setShowRentModal(true)}
                                    />
                                )}
                        </div>
                    )}

                    {activeLoanQuery?.data && (
                        <div className='col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-6'>
                            <LoanCard
                                loan={activeLoanQuery?.data}
                                openDetail={handleLoanDetail}
                                hasDeferral={
                                    deferralOrderQuery?.data !== null &&
                                    deferralOrderQuery?.data !== undefined
                                }
                                setOpenPaymentModal={setOpenPaymentModal}
                                setOpenDeferralModal={setOpenDeferralModal}
                            />
                            <LoanCardMobile
                                loan={activeLoanQuery?.data}
                                openDetail={handleLoanDetail}
                                hasDeferral={
                                    deferralOrderQuery?.data !== null &&
                                    deferralOrderQuery?.data !== undefined
                                }
                                setOpenPaymentModal={setOpenPaymentModal}
                                setOpenDeferralModal={setOpenDeferralModal}
                            />

                            {deferralOrderQuery?.data && (
                                <div className={'pl-10 mt-5 relative'}>
                                    <DeferralCard
                                        deferral={deferralOrderQuery?.data}
                                        payDeferral={handlePayDeferral}
                                        stornoDeferral={() => setOpenCancellationModal(true)}
                                    />
                                    <DeferralCardMobile
                                        deferral={deferralOrderQuery?.data}
                                        payDeferral={handlePayDeferral}
                                        stornoDeferral={() => setOpenCancellationModal(true)}
                                    />
                                </div>
                            )}

                            {!customerQuery?.data?.papers_uploaded && <UploadPapersBanner />}

                            {contractsToSignQuery?.data &&
                                contractsToSignQuery?.data?.results?.filter(
                                    (contract: Contract) =>
                                        contract.type !== 9 &&
                                        contract.type !== 12 &&
                                        contract.latest_signi_status !== 'rejected',
                                )?.length > 0 && (
                                    <>
                                        {contractsToSignQuery?.data.results?.[0]?.signi_sign_url &&
                                            contractsToSignQuery?.data.results?.[0]
                                                ?.latest_signi_status === 'client_pending' && (
                                                <SignContractBannerSigni
                                                    setShowSignContractModal={
                                                        setShowSignContractModal
                                                    }
                                                    contracts={contractsToSignQuery?.data}
                                                />
                                            )}
                                    </>
                                )}

                            {contractsToSignQuery?.data &&
                                contractsToSignQuery?.data?.results?.filter(
                                    (contract: Contract) =>
                                        contract.type !== 9 &&
                                        contract.latest_signi_status === 'rejected',
                                )?.length > 0 && (
                                    <SigniContractRejectModal
                                        open={showSignContractModal}
                                        setOpen={() => setShowSignContractModal(true)}
                                        closeModal={() => setShowSignContractModal(false)}
                                        handleCloseModal={() => {
                                            setClosedSignContractModal(true)
                                            setShowSignContractModal(false)
                                        }}
                                    />
                                )}
                        </div>
                    )}

                    {/* Second col */}
                    <div className='col-span-12 sm:col-span-12 md:col-span-8 xl:col-span-5'>
                        <RentBanner />
                    </div>
                </div>
            </section>

            {activeLoanQuery?.data && (
                <>
                    <DeferralModal
                        open={openDeferralModal}
                        setOpen={setOpenDeferralModal}
                        deferral={deferralOrderQuery?.data}
                        loan={activeLoanQuery?.data}
                        showPaymentMethodForm={showPaymentMethodForm}
                        setShowPaymentMethodForm={setShowPaymentMethodForm}
                        handleDeferral={handleDeferral}
                        customer={customerQuery?.data}
                        setShowPaymentForm={setShowPaymentForm}
                        showPaymentForm={showPaymentForm}
                    />
                    <PaymentModal
                        open={openPaymentModal}
                        setOpen={setOpenPaymentModal}
                        loan={activeLoanQuery?.data}
                        customer={customerQuery?.data}
                    />
                </>
            )}
            {openCancellationModal && (
                <CancelationModal
                    onClick={async () => {
                        await handleCancellationDeferral()
                        setShowPaymentMethodForm(false)
                        setShowPaymentForm(true)
                    }}
                    onClose={() => setOpenCancellationModal(false)}
                    open={openCancellationModal}
                    setOpen={setOpenCancellationModal}
                />
            )}

            {!customerQuery?.data?.distance_amendment_signed &&
                contractsToSignQuery?.data &&
                contractsToSignQuery?.data?.results?.filter(
                    (contract: Contract) => contract.type === 9,
                )?.length > 0 && (
                    <AmendmentModal
                        open={openAmendmentModal}
                        setOpen={setOpenAmendmentModal}
                        handleSignAmendment={handleSignDistanceAmendment}
                        file={contractsToSignQuery?.data.results[0].url}
                    />
                )}

            {contractsToSignQuery?.data &&
                contractsToSignQuery?.data?.results?.filter(
                    (contract: Contract) => contract.type === 12,
                )?.length > 0 && (
                    <AmendmentRSModal
                        open={openAmendmentRSModal}
                        setOpen={setOpenAmendmentRSModal}
                        handleSignAmendment={handleSignDistanceAmendment}
                        file={contractsToSignQuery?.data.results[0].url}
                    />
                )}

            {showLoanDetailModal && (
                <DetailModal
                    open={showLoanDetailModal}
                    setOpen={() => setShowLoanDetailModal(true)}
                    closeModal={() => setShowLoanDetailModal(false)}
                    loan={loanDetailQuery?.data}
                />
            )}

            {showRentModal && (
                <RentModal
                    open={showRentModal}
                    setOpen={() => setShowRentModal(true)}
                    onClose={() => setShowRentModal(false)}
                />
            )}
        </PageWrapLayout>
    )
}

export default Dashboard
